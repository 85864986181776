import { MTP_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import {
  http,
  handlerErros,
  handleOtherErrors
} from '../../../services/http-wrapper';

//=========GET ALL COMPANY LIST=============//

export const ApiGetCompany = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/usuario_empresa/read`,
      user.getIdToken().getJwtToken()
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//=============GET COMPANY BY ID==================//

export const ApiGetCompanyById = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/company/get/${id}`,
      user.getIdToken().getJwtToken(),
      id
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//=========SAVE COMPANY DETAILS=============//

export const ApiSaveCompany = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/records/company/save`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//============COMPANY DELETE ROW================//

export const ApiDeleteCadastrarEmpresa = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/records/company/delete/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleOtherErrors('cadastros', error.response.status);
    handlerErros(error);
  }
};

//============COMPANY CNPJ DATA================//

export const ApiCadastrarEmpresaCnpj = async ({ cnpj }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/receita_cnpj/${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//============CLIENT CNPJ DATA================//

export const ApiCadastrarClientCnpj = async ({ cnpj }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/receita_cnpj/${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
