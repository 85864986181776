import { lazy, Suspense } from 'react';
//AWS
import {
  Authenticator,
  Image,
  Text,
  useTheme,
  View
} from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import awsmobile from './aws-exports';
//COMPONENTS
import Logo from './assets/logo_maketheway_vetorizado.png';
import LoadingAnimation from 'components/MTWFeedback/Loading';
import MakeBIHome from 'project/make-bi/pages/MakeBIHome';
import QSearchBar from 'project/make-bi/pages/QSearchHome';
//REACT ROUTER
import { Route, Routes } from 'react-router-dom';
import { MAKEBI, QSEARCHBAR, DLMWORKFLOWS } from 'Routes';
//REACT LAZY
const WelcomeSection = lazy(() =>
  import('./project/portal/pages/WelcomeSection')
);
const MakeThePrice = lazy(() => import('./project/make-the-price'));
const Elementar = lazy(() => import('./project/elementar'));
const CadastrosBasicos = lazy(() => import('./project/cadastros-basicos'));
const NotaAvulsa = lazy(() => import('./project/make-forward'));
const MakeForwardNfse = lazy(() => import('./project/make-forward-nfse'));
const MakeForwardCte = lazy(() => import('./project/make-forward-cte'));
const SubidaDeDocumentos = lazy(() => import('./project/upload'));
const SubidaDeDocumentosDE = lazy(() => import('./project/upload-de'));
const SubidaDeDocumentosMakeView = lazy(() =>
  import('./project/make-view-upload')
);
const SolicitarDocumentos = lazy(() =>
  import('./project/solicitar-documentos')
);
const BaseLegal = lazy(() => import('./project/base-legal'));
const DefinirCategorias = lazy(() => import('./project/definir-categorias'));
const MakeView = lazy(() => import('./project/make-view'));
const MakeViewFiles = lazy(() => import('./project/make-view-files'));
const IntegrationsManagement = lazy(() =>
  import('./project/make-forward-integrations')
);
const MTWHiddenPage = lazy(() => import('./project/hidden-page'));
const Reports = lazy(() => import('./project/reports'));
const NcmSelector = lazy(() => import('./project/ncm-selector'));
const Page404 = lazy(() => import('./project/page-not-found'));
const HealthCheck = lazy(() => import('./project/health-check'));
const TaxInformation = lazy(() => import('./project/tax-information-page'));
const LifeCycle = lazy(() => import('./project/document-lifecycle'));
const DlmWorkflow = lazy(() => import('./project/dlm-workflows'));
const Diagrams = lazy(() => import('./components/JointJSWorkflow'));
//SERVICES
import { ToastContainer } from 'react-toastify';
//STYLE
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { IconContainer } from './styledComponentsStyles';
import theme from './theme';
import styledtheme from './styledThemeOn';

Amplify.configure(awsmobile);
Auth.configure(awsmobile);

function App() {
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Make the Way"
            src={Logo}
            height={50}
            sx={{ background: 'tomato' }}
          />
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={`${tokens.colors.neutral['80']}`}>
            &copy; Todos os direitos reservados
          </Text>
        </View>
      );
    }
  };

  return (
    <>
      <Authenticator
        className="auth"
        loginMechanisms={['email']}
        components={components}
      >
        {() => (
          <main>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={styledtheme}>
                <Suspense
                  fallback={
                    <IconContainer>
                      <LoadingAnimation />
                    </IconContainer>
                  }
                >
                  <Routes>
                    <Route index element={<WelcomeSection />} />
                    <Route path="/" element={<WelcomeSection />} />
                    <Route path={MAKEBI} element={<MakeBIHome />} />
                    <Route path={QSEARCHBAR} element={<QSearchBar />} />
                    <Route path="mtp/home/*" element={<MakeThePrice />} />
                    <Route path="elementar/home/*" element={<Elementar />} />
                    <Route
                      path="cadastros-basicos/home/*"
                      element={<CadastrosBasicos />}
                    />
                    <Route path="/make-forward*" element={<NotaAvulsa />} />
                    <Route
                      path="/make-forward-nfse*"
                      element={<MakeForwardNfse />}
                    />
                    <Route
                      path="/make-forward-cte*"
                      element={<MakeForwardCte />}
                    />
                    <Route
                      path="/subida-documentos*"
                      element={<SubidaDeDocumentos />}
                    />
                    <Route
                      path="/upload-documents-de*"
                      element={<SubidaDeDocumentosDE />}
                    />
                    <Route
                      path="/solicitar-documentos*"
                      element={<SolicitarDocumentos />}
                    />
                    <Route path="/base-legal*" element={<BaseLegal />} />
                    <Route
                      path="/definir-categorias*"
                      element={<DefinirCategorias />}
                    />
                    <Route path="/make-view*" element={<MakeView />} />
                    <Route
                      path="/subida-documentos-make-view*"
                      element={<SubidaDeDocumentosMakeView />}
                    />
                    <Route
                      path="/make-view-files*"
                      element={<MakeViewFiles />}
                    />
                    <Route
                      path="/integrations-management*"
                      element={<IntegrationsManagement />}
                    />
                    <Route
                      path="/mtw-hidden-page*"
                      element={<MTWHiddenPage />}
                    />
                    <Route path="/reports*" element={<Reports />} />
                    <Route path="/ncm-selector*" element={<NcmSelector />} />
                    <Route path="*" element={<Page404 />} />
                    <Route path="/health-check*" element={<HealthCheck />} />
                    <Route
                      path="/tax-information-page*"
                      element={<TaxInformation />}
                    />
                    <Route path="/dlm*" element={<LifeCycle />} />
                    <Route path={DLMWORKFLOWS} element={<DlmWorkflow />} />
                    <Route path="/diagrams*" element={<Diagrams />} />
                  </Routes>
                </Suspense>
              </ThemeProvider>
            </MuiThemeProvider>
          </main>
        )}
      </Authenticator>

      <ToastContainer />
    </>
  );
}

export default App;
