import { MTP_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import {
  handlerErros,
  http,
  handleAdditionalErrors
} from 'services/http-wrapper';

//--------------List of users attached to a company from a client in Cadastros Básicos--------------
export const ApiGetUsersByCompany = async ({
  id_usuario,
  id_empresa,
  id_cliente
}) => {
  let chosenParam;
  if (id_usuario) {
    chosenParam = `id_usuario=${id_usuario}`;
  } else {
    chosenParam = `id_empresa=${id_empresa}`;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/usuario_empresa/list?${chosenParam}&id_cliente=${id_cliente}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//--------------Delete an user attached to a company (that belongs to a client) in Cadastros Básicos--------------
export const ApiDeleteUserFromCompany = async ({ cuser, company }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/records/usuario_empresa/delete/user/${cuser}/company/${company}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//--------------Attach an user to a company (that belongs to a client) in Cadastros Básicos--------------
export const ApiAttachUserToCompany = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/records/usuario_empresa/create`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
/*
export const ApiAttachUserToCompany = async ({ id_empresa, id_usuario }) => {
  let url = `${MTP_BASE_URL}/records/usuario_empresa/create`;
  let data = {
    id_empresa: id_empresa,
    id_usuario: id_usuario
  };
  try {
    const api = await http.simplePost(url, data);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
*/

//=========GET ALL CLIENT LIST=============//
export const ApiGetClient = async ({ page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/client/list?page=${
        page + 1
      }&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { page },
      { per_page }
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//=========SAVE CLIENT DETAILS=============//
export const ApiSaveClient = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/records/client/save`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiSingleClient = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/client/get/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//=========UPDATE CLIENT DETAILS=============//
export const ApiUpdateClient = async ({ data, id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${MTP_BASE_URL}/records/client/update/${id}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//================= GET COMPANIES BY CLIENT ==============//
export const ApiCompanyByClient = async ({ id, page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/company/list_by_client/${id}?page=${
        page + 1
      }&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//=========UPDATE CLIENT COMPANY DETAILS=============//
export const ApiUpdateClientCompany = async ({ data, id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${MTP_BASE_URL}/records/company/update/${id}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleAdditionalErrors('cadastros', error.response.status);
    handlerErros(error);
  }
};

//============CLIENT DELETE ROW================//
export const ApiDeleteCadastrar = async ({ id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/records/client/delete/${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//====================CLIENT DASHBOARD===================//
export const ApiClientDashboard = async ({ client_id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/records/cliente_dashboard_bi/list?client_id=${client_id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiClientDashboardSave = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/records/cliente_dashboard_bi/save`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
