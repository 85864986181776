import {
  NOTA_AVULSA_BASE_URL,
  MTP_BASE_URL,
  ELEMENTAR_BASE_URL,
  HUMAN_LOOP_BASE_URL
} from 'apiRoutes';
import {
  http,
  handlerErros,
  handleApiErrors,
  handleMessages
} from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';

// HUMAN LOOP
export const ApiNcmSelector = async ({ ncm, ufs }) => {
  let message = 'NCM enviado com sucesso';
  let data = {
    ncm: ncm,
    ufs: ufs
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.altPost(
      `${HUMAN_LOOP_BASE_URL}/human_loop/check_point`,
      data,
      user.getIdToken().getJwtToken(),
      message
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostLoopNcm = async ({ ncm, description }) => {
  let data = {
    ncm: ncm,
    description: description
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${HUMAN_LOOP_BASE_URL}/human_loop/ncm_model`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// MAKE FORWARD TAGS
export const ApiPostTag = async ({ tag_name, cnpj, cor, tipo_documento }) => {
  let data = {
    tag_name: tag_name,
    cnpj: cnpj,
    cor: cor,
    tipo_documento: tipo_documento
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/create/tag`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetTags = async ({ cnpj, tipo_documento }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/tag?cnpj=${cnpj}&tipo_documento=${tipo_documento}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handleMessages(error);
  }
};

export const ApiDeleteTag = async (Id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.voidRemove(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/delete/tag/${Id}`,
      user.getIdToken().getJwtToken(),
      { Id }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//MAKE FORWARD TAGS TAG AVISO
export const ApiGetTagAviso = async (id_tag) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/tag_aviso?id_tag=${id_tag}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostTagAviso = async ({
  id_tag,
  email,
  whatsapp,
  desc_aviso
}) => {
  let data = {
    id_tag: id_tag,
    email: email,
    whatsapp: whatsapp,
    desc_aviso: desc_aviso
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/create/tag_aviso`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiUpdateTagAviso = async ({
  id_tag,
  email,
  whatsapp,
  desc_aviso
}) => {
  let data = {
    id_tag: id_tag,
    email: email,
    whatsapp: whatsapp,
    desc_aviso: desc_aviso
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/update/tag_aviso`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};

export const ApiDeleteTagAviso = async (id_aviso) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/delete/tag_aviso/${id_aviso}`,
      user.getIdToken().getJwtToken(),
      { id_aviso }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//MAKE FORWARD TAGS TAG WORKFLOW
export const ApiGetTagWorkflow = async ({
  cnpj,
  page,
  per_page,
  tipo_documento
}) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.customMessageGet(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/tag_workflow?cnpj=${cnpj}&page=${validPage}&per_page=${per_page}&tipo_documento=${tipo_documento}`,
      user.getIdToken().getJwtToken(),
      'no-company-workflow'
    );
    return api.data;
  } catch (error) {
    handleMessages(error);
  }
};

export const ApiPostTagWorkflow = async ({
  tipo_nota,
  id_tag_orig,
  id_tag_dest,
  cnpj,
  tipo_validacao,
  tipo_documento
}) => {
  let data = {
    tipo_nota: tipo_nota,
    id_tag_orig: id_tag_orig,
    id_tag_dest: id_tag_dest,
    cnpj: cnpj,
    tipo_validacao: tipo_validacao,
    tipo_documento: tipo_documento
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.voidPost(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/create/tag_workflow`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiDeleteTagWorkflow = async (tag_workflow) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/delete/tag_workflow/${tag_workflow}`,
      user.getIdToken().getJwtToken(),
      { tag_workflow }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//MAKE FORWARD TAGS TAG NOTA
export const ApiPostTagNota = async ({ id_notas, id_tag, tipo_documento }) => {
  let data = {
    id_notas: id_notas,
    id_tag: id_tag,
    tipo_documento: tipo_documento
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/create/tag_nota`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetTagValidation = async ({
  id_notas,
  id_tag,
  status,
  tipo_documento
}) => {
  let data = {
    id_notas: id_notas,
    id_tag: id_tag,
    status: status,
    tipo_documento: tipo_documento
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/validar_tag`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
  }
};

// MAKE FORWARD INTEGRATIONS
export const ApiPostTagExecucao = async ({
  id_tag,
  execucao,
  lambda,
  descricao_lambda
}) => {
  let data = {};
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/create/tag_execucao?id_tag=${id_tag}&execucao=${execucao}&Lambda=${lambda}&descricao_Lambda=${descricao_lambda}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetTagExecucoes = async ({ id_tag, page, per_page }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/tag_execucao?id_tag=${id_tag}&page=${validPage}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiUpdateTagExecucao = async ({
  integration,
  descricao_Lambda,
  execucao,
  id_tag,
  Lambda
}) => {
  let data = {
    descricao_Lambda: descricao_Lambda,
    execucao: execucao,
    id_tag: id_tag,
    Lambda: Lambda
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/update/tag_execucao/${integration}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};

export const ApiDeleteTagExecucao = async (tag_execucao) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/delete/tag_execucao/${tag_execucao}`,
      user.getIdToken().getJwtToken(),
      { tag_execucao }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// MAKE FORWARD HOME PAGE ACTIONS
export const ApiGetZipFiles = async ({
  cnpj,
  data_inicio,
  data_fim,
  tipo_nota
}) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/stored_files/keys/${cnpj}?data_inicio=${data_inicio}&data_fim=${data_fim}&tipo_nota=${tipo_nota}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetCompanyList = async () => {
  try {
    const api = await http.simpleGet(`${NOTA_AVULSA_BASE_URL}/api/v1/empresa`);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNumericCompanyId = async (cnpj) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/get/company_by_cnpj/${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetInvoiceSystemMessage = async (empresa_id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/system_message?empresa_id=${empresa_id}&page=1&per_page=50`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostCertificateUpdate = async ({
  id,
  certificado,
  cpfCnpj,
  razaoSocial,
  senhaCertificado,
  uf
}) => {
  let url =
    typeof id === 'number'
      ? `${NOTA_AVULSA_BASE_URL}/api/v1/empresa/${id}`
      : `${NOTA_AVULSA_BASE_URL}/api/v1/empresa`;

  let data = {
    ambiente: 'PRODUCAO',
    certificado: certificado,
    cpfCnpj: cpfCnpj,
    razaoSocial: razaoSocial,
    senhaCertificado: senhaCertificado,
    uf: uf
  };
  try {
    const api = await http.simplePost(url, data);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostNotaFiles = async ({
  arquivoXml,
  idEmpresa,
  tipoNota,
  nome
}) => {
  let url = `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada/uploadNota`;
  let data = {
    arquivoXml: arquivoXml,
    idEmpresa: idEmpresa,
    tipoNota: tipoNota,
    nome: nome
  };
  try {
    const api = await http.simplePost(url, data);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostNfseFiles = async ({
  arquivoXml,
  codMunicipio,
  idEmpresa,
  tipoNota
}) => {
  let url = `${NOTA_AVULSA_BASE_URL}/api/v1/notas-servico/uploadNota`;
  let data = {
    arquivoXml: arquivoXml,
    codMunicipio: codMunicipio,
    idEmpresa: idEmpresa,
    tipoNota: tipoNota
  };
  try {
    const api = await http.simplePost(url, data);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostXlsInvoice = async (xlsInvoice) => {
  let url = `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada/excel`;
  let data = xlsInvoice;
  try {
    const api = await http.simplePost(url, data);
    return api;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiUpdateInvoiceQtd = async (body) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/update/dados_itens`,
      body,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
    throw error;
  }
};

export const ApiValidateInvoiceItems = async (body) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/update/validation_by_itens`,
      body,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ApiGetInvCodMunicipio = async (id) => {
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/empresa/${id}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// MAKE FORWARDS HOME PAGE TABLE
export const ApiGetNotasByCnpj = async ({ cnpj, page, size }) => {
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada?cnpjEmpresa=${cnpj}&page=${page}&size=${size}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNotasFiltered = async (notaAvulsaFilters) => {
  let filters = Object.keys(notaAvulsaFilters)
    .filter((pos) => notaAvulsaFilters[pos] != '')
    .reduce((a, pos) => ({ ...a, [pos]: notaAvulsaFilters[pos] }), {});
  if (notaAvulsaFilters.page === 0) {
    filters = {
      ...filters,
      page: 0
    };
  }
  if (notaAvulsaFilters.size === 0 || !notaAvulsaFilters.size) {
    filters = {
      ...filters,
      size: 20
    };
  }
  let filtersArray = Object.entries(filters);
  let queryArray = [];
  for (let i = 0; filtersArray.length > i; i++) {
    let field = filtersArray[i][0];
    let value = filtersArray[i][1];
    let queryString = `&${field}=${value}`;
    queryArray.push(queryString);
  }
  let query = queryArray.join('');
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada?${query}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNotasServico = async (notasServico) => {
  let filters = Object.keys(notasServico)
    .filter((pos) => notasServico[pos] != '')
    .reduce((a, pos) => ({ ...a, [pos]: notasServico[pos] }), {});
  if (notasServico.page === 0) {
    filters = {
      ...filters,
      page: 0
    };
  }
  if (notasServico.size === 0 || !notasServico.size) {
    filters = {
      ...filters,
      size: 20
    };
  }
  let filtersArray = Object.entries(filters);
  let queryArray = [];
  for (let i = 0; filtersArray.length > i; i++) {
    let field = filtersArray[i][0];
    let value = filtersArray[i][1];
    let queryString = `&${field}=${value}`;
    queryArray.push(queryString);
  }
  let query = queryArray.join('');
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-servico?${query}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// MAKE FORWARD INVOICE ANALYSIS
export const ApiGetNotaById = async (id) => {
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada/${id}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNCMDesc = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list?id_nota_entrada=${id}&table=erroncmnota`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNCMCNAE = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list?id_nota_entrada=${id}&table=erroncmcnae`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetICMSIncid = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list?id_nota_entrada=${id}&table=errocfopicms`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetICMSAliq = async ({ id, empresa_id }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list?id_empresa=${empresa_id}&id_nota_entrada=${id}&table=erroaliquotaicms`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// MAKE FORWARD INVOICE DETAILS
export const ApiGetInvoiceEvents = async ({ id, page, perPage }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/dados_suframa?chave_nota=${id}&page=${validPage}&per_page=${perPage}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetDocData = async ({ id, type }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list_nota?invoice_type=${type}&invoice_id=${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetDocItens = async (notaId) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/nota_avulsa/list/dados_itens?id_nota=${notaId}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetDANFE = async (id) => {
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada/impressao/${id}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetDANFESide = async (id) => {
  try {
    const api = await http.blobGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada/impressao/${id}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetDANFEServ = async (id) => {
  try {
    const api = await http.blobGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/notas-servico/impressao/${id}`
    );
    return api.data;
  } catch (error) {
    handleApiErrors(error);
  }
};

export const ApiGetInvoiceOrderItems = async (notaId) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/pedido/list/fornecedor?nota_id=${notaId}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetOrderByInvId = async ({ nota_id, page, per_page }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/pedido/list/by_nota?nota_id=${nota_id}&page=${validPage}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetInvOrder = async ({ page, per_page }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/pedido/list/pedido?page=${validPage}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetOrderItem = async ({ page, per_page }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/pedido/list/item_pedido?page=${validPage}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetOrderById = async ({ id, page, per_page }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/pedido/list/pedido/${id}?page${validPage}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetOrderByCnpj = async (cnpj) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/pedido/list/pedido/cnpj?cnpj=${cnpj}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostOrderConfirmation = async ({
  id_dados_itens,
  id_item_ped,
  quantidade
}) => {
  let data = {
    id_dados_itens: id_dados_itens,
    id_item_ped: id_item_ped,
    quantidade: quantidade
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/pedido/validacao`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostInvOrder = async ({
  cnpj_emit,
  cnpj_dest,
  data_emissao,
  tipo_fret,
  num_pedido
}) => {
  let data = {
    cnpj_emit: cnpj_emit,
    cnpj_dest: cnpj_dest,
    data_emissao: data_emissao,
    tipo_fret: tipo_fret,
    num_pedido: num_pedido
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/pedido/create/pedido`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostInvOrderItem = async ({
  id_pedido,
  num_item_pedido,
  cod_prod_interno,
  unid_medida_interna,
  qtd_pedido,
  vl_unitario,
  cod_prod_fornecedor,
  unid_medida_fornecedor,
  taxa_conversao,
  tipo_conversao
}) => {
  let data = {
    id_pedido: id_pedido,
    num_item_pedido: num_item_pedido,
    cod_prod_interno: cod_prod_interno,
    unid_medida_interna: unid_medida_interna,
    qtd_pedido: qtd_pedido,
    vl_unitario: vl_unitario,
    cod_prod_fornecedor: cod_prod_fornecedor,
    unid_medida_fornecedor: unid_medida_fornecedor,
    taxa_conversao: taxa_conversao,
    tipo_conversao: tipo_conversao
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/pedido/create/item_pedido`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostInvOrderByFile = async ({ xml }) => {
  let data = {
    xml: xml
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/pedido/create/by_xml`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiUpdateInvOrder = async ({
  id,
  cnpj_emit,
  cnpj_dest,
  data_emissao,
  tipo_fret,
  num_pedido
}) => {
  let data = {
    cnpj_emit: cnpj_emit,
    cnpj_dest: cnpj_dest,
    data_emissao: data_emissao,
    tipo_fret: tipo_fret,
    num_pedido: num_pedido
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/pedido/update/pedido/${id}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
    throw error;
  }
};

export const ApiUpdateInvOrderItem = async ({
  id,
  num_item_pedido,
  cod_prod_interno,
  unid_medida_interna,
  qtd_pedido,
  vl_unitario,
  cod_prod_fornecedor,
  unid_medida_fornecedor,
  taxa_conversao,
  tipo_conversao
}) => {
  let data = {
    num_item_pedido: num_item_pedido,
    cod_prod_interno: cod_prod_interno,
    unid_medida_interna: unid_medida_interna,
    qtd_pedido: qtd_pedido,
    vl_unitario: vl_unitario,
    cod_prod_fornecedor: cod_prod_fornecedor,
    unid_medida_fornecedor: unid_medida_fornecedor,
    taxa_conversao: taxa_conversao,
    tipo_conversao: tipo_conversao
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.put(
      `${ELEMENTAR_BASE_URL}/pedido/update/item_pedido/${id}`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
    throw error;
  }
};

export const ApiDeleteInvOrder = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_BASE_URL}/pedido/delete/pedido/${id}`,
      user.getIdToken().getJwtToken(),
      { id }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiDeleteInvOrderItem = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${ELEMENTAR_BASE_URL}/pedido/delete/item_pedido/${id}`,
      user.getIdToken().getJwtToken(),
      { id }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// MAKE FORWARD INVOICE ERRORS
export const ApiGetInvoiceErrors = async ({ id, page, per_page }) => {
  let validPage;
  if (page === 0 || page === '0') {
    validPage = 1;
  } else {
    validPage = page;
  }
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/erronota/list?id_nota_entrada=${id}&page=${validPage}&per_page=${per_page}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetCrossedNcm = async ({ ncm, ncm_pred }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/erronota/get_item?ncm=${ncm}&ncm_pred=${ncm_pred}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// OTHER
export const ApiPostNotaManifestacao = async ({
  id,
  tipoManifestacao,
  motivo
}) => {
  let url = `${NOTA_AVULSA_BASE_URL}/api/v1/notas-entrada/manifestar/${id}`;
  let data = {
    tipoManifestacao: tipoManifestacao,
    motivo: motivo
  };
  try {
    const api = await http.simplePost(url, data);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// CTE
export const ApiGetNotasCte = async (notasCte) => {
  let filters = Object.keys(notasCte)
    .filter((pos) => notasCte[pos] != '')
    .reduce((a, pos) => ({ ...a, [pos]: notasCte[pos] }), {});
  if (notasCte.page === 0) {
    filters = {
      ...filters,
      page: 0
    };
  }
  if (notasCte.size === 0 || !notasCte.size) {
    filters = {
      ...filters,
      size: 20
    };
  }
  let filtersArray = Object.entries(filters);
  let queryArray = [];
  for (let i = 0; filtersArray.length > i; i++) {
    let field = filtersArray[i][0];
    let value = filtersArray[i][1];
    let queryString = `&${field}=${value}`;
    queryArray.push(queryString);
  }
  let query = queryArray.join('');
  try {
    const api = await http.simpleGet(
      `${NOTA_AVULSA_BASE_URL}/api/v1/cte?${query}`
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiPostNotaCte = async ({ arquivoXml, idEmpresa, tipoNota }) => {
  let url = `${NOTA_AVULSA_BASE_URL}/api/v1/cte/uploadCte`;
  let data = {
    arquivoXml: arquivoXml,
    idEmpresa: idEmpresa,
    tipoNota: tipoNota
  };
  try {
    const api = await http.simplePost(url, data);
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
