import { MTP_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import { handlerErros, http } from 'services/http-wrapper';

export const ApiPostQsDb = async (body) => {
  let user = await Auth.currentSession();
  //const payloadsub = user.getIdToken().payload.sub;
  const email = user.getIdToken().payload.email;
  let cnpjs = body.cnpjs;
  let dashboardid = body.dashboardid;
  let cnpjsList = [];
  for (let i = 0; cnpjs.length > i; i++) {
    let cnpj = `${cnpjs[i]}`;
    cnpjsList.push(cnpj);
  }
  let cnpjsString = cnpjsList.join(';');
  const filter = {
    dashboard_id: dashboardid,
    email: email,
    tags: [
      {
        Key: 'cnpj',
        Value: `${cnpjsString}`
      }
    ]
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.voidPost(
      `${MTP_BASE_URL}/embedurlquicksight/anonymous_url`,
      filter,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    //handlerErros(error);
    console.log(error);
  }
};

/*
//deprecated
export const ApiGetQsdb = async ({ dashboardid }) => {
  try {
    let user = await Auth.currentSession();
    const payloadsub = user.getIdToken().payload.sub;
    const email = user.getIdToken().payload.email;
    const options = {
      response: true,
      queryStringParameters: {
        jwtToken: user.getIdToken().getJwtToken(),
        payloadSub: payloadsub,
        email: email
      }
    };
    const api = await http.get(
      `${MTP_BASE_URL}/embedurlquicksight/anonymous_url`,
      user.getIdToken().getJwtToken(),
      options
    );
    return api.data;
  } catch (error) {
    alert(error);
    handlerErros(error);
  }
};
*/

export const ApiGetQsdblist = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/quicksightclient/dashboardlist`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//1
export const ApiGetQsTopiclist = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/quicksightclient/topiclist`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//2
export const ApiGetQsTopicSearch = async ({ topicid }) => {
  try {
    let user = await Auth.currentSession();
    const email = user.getIdToken().payload.email;
    const payloadsub = user.getIdToken().payload.sub;
    const options = {
      response: true,
      queryStringParameters: {
        jwtToken: user.getIdToken().getJwtToken(),
        payloadSub: payloadsub,
        email: email
      }
    };
    const api = await http.get(
      `${MTP_BASE_URL}/embedurlquicksight/get_q?topic_id=${topicid}&email=${email}`,
      user.getIdToken().getJwtToken(),
      options
    );
    return api.data;
  } catch (error) {
    alert(error);
    handlerErros(error);
  }
};
