import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiCadastrarClientCnpj,
  ApiCadastrarEmpresaCnpj,
  ApiDeleteCadastrarEmpresa,
  ApiGetCompany,
  ApiGetCompanyById,
  ApiSaveCompany
} from './company.api';

export const getPageFrame = createAsyncThunk('pageframe', async ({ bool }) => {
  if (bool) {
    return true;
  } else if (!bool) {
    return false;
  }
});

export const getCompanyList = createAsyncThunk('api/companies', async () => {
  let response = await ApiGetCompany();
  return response;
});

export const getSingleCompany = createAsyncThunk(
  'api/singlecompany',
  async ({ id }) => {
    let response = await ApiGetCompanyById({ id });
    return response;
  }
);

//======SAVE COMPANY========//

export const saveCompany = createAsyncThunk(
  'api/savecompanies',
  // eslint-disable-next-line no-unused-vars
  async (data) => {
    let response = await ApiSaveCompany(data);
    let { status, message, payload = {} } = response;

    return { status, message, payload };
  }
);

export const deleteCadastrarEmpresa = createAsyncThunk(
  'api/deletecadastrarempresa',
  async ({ id }) => {
    let response = await ApiDeleteCadastrarEmpresa({ id });
    return response;
  }
);

export const CnpjCadastrarEmpresa = createAsyncThunk(
  'api/cadastrarempresacnpjdata',
  async ({ cnpj }) => {
    let response = await ApiCadastrarEmpresaCnpj({ cnpj });
    return response;
  }
);

export const CnpjCadastrarClient = createAsyncThunk(
  'api/cadastrarclientcnpjdata',
  async ({ cnpj }) => {
    let response = await ApiCadastrarClientCnpj({ cnpj });
    return response;
  }
);

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    companies: [],
    singlecompany: {},
    isGettingCompany: false,
    savecompany: {},
    issavecompany: false,
    isGettingCompanies: false,
    deleteEmpresa: false,
    empresaCnpj: {},
    isGettingEmpresaCnpjData: false,
    clientCnpj: {},
    isGettingClientCnpjData: false,
    //Page's frame (navbar and first row):
    pageFrame: true
  },
  reducers: {},
  extraReducers: {
    [getPageFrame.fulfilled]: (state, action) => {
      state.pageFrame = action.payload;
    },
    [getCompanyList.pending]: (state) => {
      state.isGettingCompanies = 'loading';
    },
    [getCompanyList.fulfilled]: (state, action) => {
      state.isGettingCompanies = 'success';
      state.companies = action.payload;
    },
    [getCompanyList.rejected]: (state) => {
      state.isGettingCompanies = 'failed';
    },
    [getSingleCompany.pending]: (state) => {
      state.isGettingCompany = 'loading';
    },
    [getSingleCompany.fulfilled]: (state, action) => {
      state.isGettingCompany = 'success';
      state.singlecompany = action.payload;
    },
    [getSingleCompany.rejected]: (state) => {
      state.isGettingCompany = 'failed';
    },
    [saveCompany.pending]: (state) => {
      state.issavecompany = 'loading';
    },
    [saveCompany.fulfilled]: (state, action) => {
      state.issavecompany = 'success';
      state.savecompany = action.payload;
    },
    [saveCompany.rejected]: (state) => {
      state.issavecompany = 'failed';
    },
    [deleteCadastrarEmpresa.pending]: (state) => {
      state.deleteEmpresa = 'loading';
    },
    [deleteCadastrarEmpresa.fulfilled]: (state) => {
      state.deleteEmpresa = 'success';
    },
    [deleteCadastrarEmpresa.rejected]: (state) => {
      state.deleteEmpresa = 'failed';
    },
    [CnpjCadastrarEmpresa.pending]: (state) => {
      state.isGettingEmpresaCnpjData = 'loading';
    },
    [CnpjCadastrarEmpresa.fulfilled]: (state, action) => {
      state.isGettingEmpresaCnpjData = 'success';
      state.empresaCnpj = action.payload;
    },
    [CnpjCadastrarEmpresa.rejected]: (state) => {
      state.isGettingEmpresaCnpjData = 'failed';
    },
    [CnpjCadastrarClient.pending]: (state) => {
      state.isGettingClientCnpjData = 'loading';
    },
    [CnpjCadastrarClient.fulfilled]: (state, action) => {
      state.isGettingClientCnpjData = 'success';
      state.clientCnpj = action.payload;
    },
    [CnpjCadastrarClient.rejected]: (state) => {
      state.isGettingClientCnpjData = 'failed';
    }
  }
});

export const companySelector = (state) => state.company;
export default companySlice.reducer;
