import { ELEMENTAR_BASE_URL, HUMAN_LOOP_BASE_URL } from 'apiRoutes';
import { http, handlerErros } from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';

export const ApiPostLegalBase = async ({
  initial_date,
  final_date,
  cnpj,
  nat_oper,
  market,
  estab_rem,
  tributation
}) => {
  let data = {
    initial_date: initial_date,
    final_date: final_date,
    cnpj: cnpj,
    nat_oper: nat_oper,
    market: market,
    estab_rem: estab_rem,
    tributation: tributation
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${ELEMENTAR_BASE_URL}/legal_base/execution`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNCMList = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/legal_base/get/ncm_distinct`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetNCMInfo = async ({ cnpj, ncm }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/legal_base/get/ncm_result?cnpj=${cnpj}&ncm=${ncm}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

// Tax Information Page
export const ApiTaxInformation = async ({
  day,
  org,
  org_subs,
  ato,
  filtero_tributos,
  filtero_keyword
}) => {
  let isFilter = org || org_subs || ato ? true : false;
  let filtersForOrgSubsAto = {
    active: 'true',
    org: org?.length > 0 ? org : 'Todos',
    org_subs: org_subs?.length > 0 ? org_subs : 'Todos',
    ato: ato?.length > 0 ? ato : 'Todos'
  };
  let noFiltersOrgSubsAto = {
    active: 'false'
  };
  let data = {
    day: day,
    filtero_org_subs_ato: isFilter ? filtersForOrgSubsAto : noFiltersOrgSubsAto,
    filtero_tributos: filtero_tributos ? 'true' : 'false',
    filtero_keyword: filtero_keyword ? 'true' : 'false'
  };
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${HUMAN_LOOP_BASE_URL}/dou/post`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
