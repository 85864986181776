import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

axios.defaults.headers['Accept-Language'] = 'pt';

export const http = {
  get,
  post,
  put,
  remove,
  simpleGet,
  customMessageGet,
  simplePost,
  simplePut,
  voidPut,
  dlmPut,
  voidRemove,
  altPost,
  voidPost,
  dlmPost,
  blobGet
};

const config = {
  position: 'top-right',
  autoClose: '6000',
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

// Make the Price BaseURL = https://tqbtej6d7l.execute-api.us-east-1.amazonaws.com
// Elementar BaseURL = https://uo8q51ckb3.execute-api.us-east-1.amazonaws.com

async function simpleGet(path) {
  return axios
    .get(path, {
      headers: {}
    })
    .then((response) => response)
    .catch((error) => {
      handlerErros(error);
    });
}

async function blobGet(path) {
  return axios
    .get(path, { responseType: 'arraybuffer' })
    .then((response) => response)
    .catch((error) => {
      handlerErros(error);
    });
}

async function simplePost(path, data) {
  return axios
    .post(path, data)
    .then((response) => response)
    .catch((error) => {
      handlerErros(error);
    });
}

async function get(path, token) {
  return axios
    .get(path, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      handlerErros(error);
    });
}

async function customMessageGet(path, token, typeOfError) {
  return axios
    .get(path, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      customError(error, typeOfError);
    });
}

async function post(path, data, token) {
  return axios
    .post(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      handlerErros(error);
    });
}

async function voidPost(path, data, token) {
  return axios
    .post(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });
}

async function dlmPost(path, data, token) {
  return axios
    .post(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });
}

async function altPost(path, data, token, message) {
  return axios
    .post(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      response;
      toast.success(`${message}`);
    })
    .catch((error) => {
      handlerErros(error);
      // console.log(error.response.data.error.message);
      // throw error;
    });
}

async function put(path, data, token) {
  // let loader = Vue.$loading.show();
  return axios
    .put(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      response;
      //console.log('response:', response);
      toast.success('Atualização realizada com sucesso', config);
    })
    .catch((error) => {
      handlerErros(error);
    });
  // finally(() => loader.hide());
}

async function simplePut(path, data, token, done) {
  return axios
    .put(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      response;
      toast.success(`${done}`);
    })
    .catch((error) => {
      handlerErros(error);
    });
}

async function voidPut(path, data, token) {
  return axios
    .put(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      response;
    })
    .catch((error) => {
      handlerErros(error);
    });
}

async function dlmPut(path, data, token) {
  return axios
    .put(path, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => {
      response;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function remove(path, token) {
  // let loader = Vue.$loading.show();
  return axios
    .delete(path, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      handlerErros(error);
    });
  // finally(() => loader.hide());
}

async function voidRemove(path, token) {
  // let loader = Vue.$loading.show();
  return axios
    .delete(path, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });
}

export function handleApiErrors(error) {
  if (error?.response?.status) {
    const errorStatus = error.response.status;
    const errorMessage = error.response.data?.error?.message;
    const errorDataMessage = error.response.data?.message;
    const errorResponse = errorMessage || errorDataMessage;

    switch (errorStatus) {
      case 400:
        toast.error(errorResponse, config);
        break;
      case 401:
        Auth.signOut({ global: true });
        toast.error(errorResponse, config);
        break;
      case 403:
        toast.error(errorResponse, config);
        break;
      case 404:
        toast.error(errorResponse, config);
        break;
      case 405:
        toast.error(errorResponse, config);
        break;
      case 408:
        toast.error(errorResponse, config);
        break;
      case 413:
        toast.error(errorResponse, config);
        break;
      case 415:
        toast.error(errorResponse, config);
        break;
      case 429:
        toast.error(errorResponse, config);
        break;
      case 500:
        toast.error(errorResponse, config);
        break;
      case 502:
        toast.error(errorResponse, config);
        break;
      case 503:
        toast.error(errorResponse, config);
        break;
      case 504:
        toast.error(errorResponse, config);
        break;
      case 505:
        toast.error(errorResponse, config);
        break;
      default:
        toast.error(errorResponse, config);
        break;
    }
  } else {
    toast.error('Erro no servidor', config);
  }

  throw error;
}

export function handlerErros(error) {
  if (error.response != null) {
    if (error.response.status != 500 && error.response.data?.error) {
      if (!error.response.data?.error.message.includes('Company')) {
        console.log('1', error.response.data?.error.message);
        toast.error(error.response.data?.error.message);
      }
    } else if (error.response.status == 401) {
      Auth.signOut({ global: true });
    } else {
      console.log('2', error.response.data?.message, config);
      toast.error(error.response.data?.message, config);
    }
  } else {
    console.log('3', error?.response?.data?.message, config);
    toast.error(error?.response?.data?.message, config);
  }
  throw error;
}

export function customError(error, typeOfError) {
  if (error.response.data?.message) {
    let errMes = error.response.data?.message;
    if (
      errMes === 'Internal Server Error' &&
      typeOfError === 'no-company-workflow'
    ) {
      console.log('Empresa sem tags');
    }
  }
}

export function handleMessages(error) {
  if (error.message.length) {
    console.log(error.message);
  }
}

export function handleOtherErrors(product, status) {
  if (product === 'cadastros' && status == 400) {
    toast.error('Essa empresa não pertence a esse usuário');
  }
}

export function handleAdditionalErrors(product, status) {
  if (product === 'cadastros' && status == 400) {
    toast.error('Essa edição não pôde ser concluída');
  }
}
