import { MTP_BASE_URL } from 'apiRoutes';
import { Auth } from 'aws-amplify';
import { http, handlerErros } from '../../../../services/http-wrapper';

//=========SAVE PRODUCT FICHA DETAILS=============////7 fns////

export const ApiSaveFichaProduct = async (data) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.post(
      `${MTP_BASE_URL}/ficha3/download`,
      data,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    console.log(error);
    // handlerErros(error);
    throw error;
  }
};

export const ApiGetItemNotaList = async () => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/itemnota/list`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetProductFicha = async ({ idCliente, page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/produtoficha3/get/${idCliente}?page=${
        page + 1
      }&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { idCliente },
      { page },
      { per_page }
    );
    // console.log(user.getIdToken().getJwtToken());
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetListaFicha = async ({ idProductFicha, page, per_page }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/ficha3/get/${idProductFicha}?page=${
        page + 1
      }&per_page=${per_page}`,
      user.getIdToken().getJwtToken(),
      { idProductFicha },
      { page },
      { per_page }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiGetFichaStatus = async ({ idFichaBody }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/ficha3/get/status/${idFichaBody}`,
      user.getIdToken().getJwtToken(),
      { idFichaBody }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiDeleteFicha = async ({ Id_produto_ficha3 }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/delete?Id_produto_ficha3=${Id_produto_ficha3}`,
      user.getIdToken().getJwtToken(),
      { Id_produto_ficha3 }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

export const ApiChangeFichaStatus = async ({ IdProdutoFicha3 }) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${MTP_BASE_URL}/produtoficha3/change/status?IdProdutoFicha3=${IdProdutoFicha3}`,
      user.getIdToken().getJwtToken(),
      { IdProdutoFicha3 }
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
