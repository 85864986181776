import { MTP_BASE_URL, ELEMENTAR_BASE_URL } from 'apiRoutes';
import { http, handlerErros } from '../../services/http-wrapper';
import { Auth } from 'aws-amplify';
//import axios from 'axios';

//New:
export const ApiGetNewTypes = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/arquivouploadempresa/create_registers?id_empresa=${id}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//Read:
export const ApiGetAskedTypes = async (id) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.get(
      `${ELEMENTAR_BASE_URL}/arquivouploadempresa/list_files_by_company?id_empresa=${id}&person=consultor&per_page=30`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};

//Write:
export const ApiSaveAskedTypes = async (values) => {
  const doneMessage = 'Tipo de arquivo atualizado com sucesso';
  const emptyBody = {};
  let arrayToString = [];
  const validObject = Object.keys(values)
    .filter(
      (pos) =>
        values[pos] != '' && values[pos] != null && values[pos] != undefined
    )
    .reduce((a, pos) => ({ ...a, [pos]: values[pos] }), {});
  if (values.qtd === 0 || values.qtd === '') {
    validObject.qtd = 0;
  }
  const validKeys = Object.keys(validObject);
  for (let i = 1; validKeys.length > i; i++) {
    let field = validKeys[i];
    let value = validObject[field];
    let parameterString = `&${field}=${value}`;
    arrayToString.push(parameterString);
  }
  let stringToQuery = arrayToString.join('');
  let urlParams = stringToQuery.substring(1);
  let pathParams = `${values.id}?`;
  let query = pathParams + urlParams;
  if (values.id) {
    try {
      let user = await Auth.currentSession();
      const api = await http.simplePut(
        `${ELEMENTAR_BASE_URL}/arquivouploadempresa/update/${query}`,
        emptyBody,
        user.getIdToken().getJwtToken(),
        doneMessage
      );
      return api.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
};

//Delete:
export const ApiDeleteAskedTypes = async (key) => {
  try {
    let user = await Auth.currentSession();
    const api = await http.remove(
      `${MTP_BASE_URL}/stored_files/delete/${key}`,
      user.getIdToken().getJwtToken()
    );
    return api.data;
  } catch (error) {
    handlerErros(error);
  }
};
