import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ApiGetMakeViewInvValues,
  ApiGetMakeViewInvQty,
  ApiSaveAskedTypes,
  ApiDeleteAskedTypes,
  ApiGetCreateReport,
  ApiGetReportKey,
  ApiGetReportsInsumos,
  ApiGetInsumosKey
} from './makeView.api';

export const getCompanyReports = createAsyncThunk(
  'api/getreports',
  async ({ cruzam, cnpj }) => {
    let response = await ApiGetCreateReport({ cruzam, cnpj });
    return response;
  }
);

export const getCompanyReportsKeys = createAsyncThunk(
  'api/getreportskeys',
  async ({ cruzam, cnpj }) => {
    let response = await ApiGetReportKey({ cruzam, cnpj });
    return response;
  }
);

export const getMakeViewInvValues = createAsyncThunk(
  'api/getmvinvoices',
  async (id) => {
    let response = await ApiGetMakeViewInvValues(id);
    return response;
  }
);

export const getMakeViewInvQty = createAsyncThunk(
  'api/getmvinvqty',
  async (id) => {
    let response = await ApiGetMakeViewInvQty(id);
    return response;
  }
);

export const getCompanyReportsInsumos = createAsyncThunk(
  'api/getreportsinsumos',
  async ({ cnpj, data_inicio, data_fim, tipo_upload }) => {
    let response = await ApiGetReportsInsumos({
      cnpj,
      data_inicio,
      data_fim,
      tipo_upload
    });
    return response;
  }
);

export const getCompanyInsumosKey = createAsyncThunk(
  'api/getinsumoskey',
  async ({ id }) => {
    let response = await ApiGetInsumosKey({ id });
    return response;
  }
);

//Heritage:
export const saveAskedTypes = createAsyncThunk(
  'api/saveasked',
  async (values) => {
    let response = await ApiSaveAskedTypes(values);
    return response;
  }
);

export const deleteAskedTypes = createAsyncThunk(
  'api/deleteasked',
  async (key) => {
    let response = await ApiDeleteAskedTypes(key);
    return response;
  }
);

export const makeViewDataSlice = createSlice({
  name: 'makeview',
  initialState: {
    //getCompanyReportsKeys:
    isLoadingCompanyReportsKeys: false,
    companyReportsKeys: {},
    //getCompanyReports:
    isLoadingCompanyReports: false,
    companyReports: {},
    //getMakeViewInvoices:
    isLoadingMakeViewInvoices: false,
    makeViewInvValues: false,
    //getMakeViewInvQty:
    isLoadingMakeViewInvQty: false,
    makeViewInvQty: false,
    //saveAskedTypes:
    isSavingAskedTypes: false,
    savedAskedTypes: false,
    //getCompanyReportsInsumos
    isLoadingCompanyReportsInsumos: false,
    companyReportsInsumos: {},
    //getCompanyInsumosKey
    isLoadingCompanyInsumosKey: false,
    companyInsumosKey: {}
  },
  reducers: {},
  extraReducers: {
    //getCompanyReportsKeys:
    [getCompanyReportsKeys.pending]: (state) => {
      state.isLoadingCompanyReportsKeys = 'loading';
    },
    [getCompanyReportsKeys.fulfilled]: (state, action) => {
      state.isLoadingCompanyReportsKeys = 'success';
      state.companyReportsKeys = action.payload;
    },
    [getCompanyReportsKeys.rejected]: (state) => {
      state.isLoadingCompanyReportsKeys = 'failed';
    },
    //getCompanyReports:
    [getCompanyReports.pending]: (state) => {
      state.isLoadingCompanyReports = 'loading';
    },
    [getCompanyReports.fulfilled]: (state, action) => {
      state.isLoadingCompanyReports = 'success';
      state.companyReports = action.payload;
    },
    [getCompanyReports.rejected]: (state) => {
      state.isLoadingCompanyReports = 'failed';
    },
    //getMakeViewInvoices:
    [getMakeViewInvValues.pending]: (state) => {
      state.isLoadingMakeViewInvoices = 'loading';
    },
    [getMakeViewInvValues.fulfilled]: (state, action) => {
      state.isLoadingMakeViewInvoices = 'success';
      state.makeViewInvValues = action.payload;
    },
    [getMakeViewInvValues.rejected]: (state) => {
      state.isLoadingMakeViewInvoices = 'failed';
    },
    //getMakeViewInvQty:
    [getMakeViewInvQty.pending]: (state) => {
      state.isLoadingMakeViewInvQty = 'loading';
    },
    [getMakeViewInvQty.fulfilled]: (state, action) => {
      state.isLoadingMakeViewInvQty = 'success';
      state.makeViewInvQty = action.payload;
    },
    [getMakeViewInvQty.rejected]: (state) => {
      state.isLoadingMakeViewInvQty = 'failed';
    },
    //saveAskedTypes:
    [saveAskedTypes.pending]: (state) => {
      state.isSavingAskedTypes = 'loading';
    },
    [saveAskedTypes.fulfilled]: (state, action) => {
      state.isSavingAskedTypes = 'success';
      state.savedAskedTypes = action.payload;
    },
    [saveAskedTypes.rejected]: (state) => {
      state.isSavingAskedTypes = 'failed';
    },
    //getCompanyReportsInsumos
    [getCompanyReportsInsumos.pending]: (state) => {
      state.isLoadingCompanyReportsInsumos = 'loading';
    },
    [getCompanyReportsInsumos.fulfilled]: (state, action) => {
      state.isLoadingCompanyReportsInsumos = 'success';
      state.companyReportsInsumos = action.payload;
    },
    [getCompanyReportsInsumos.rejected]: (state) => {
      state.isLoadingCompanyReportsInsumos = 'failed';
    },
    //getCompanyInsumosKey
    [getCompanyInsumosKey.pending]: (state) => {
      state.isLoadingCompanyInsumosKey = 'loading';
    },
    [getCompanyInsumosKey.fulfilled]: (state, action) => {
      state.isLoadingCompanyInsumosKey = 'success';
      state.companyInsumosKey = action.payload;
    },
    [getCompanyInsumosKey.rejected]: (state) => {
      state.isLoadingCompanyInsumosKey = 'failed';
    }
  }
});

export const makeViewSelector = (state) => state.makeViewData;

export default makeViewDataSlice.reducer;
